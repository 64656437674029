'use strict';
(function () {

  class ClientDetailComponent {
    constructor($scope, $state, usSpinnerService, Util, Auth, User, Site, toastr, $http, Organization, Modal, $uibModal, $stateParams, Contact, Notes) {
      this.$stateParams = $stateParams;
      this.Organization = Organization;
      this.Contact = Contact;
      this.$scope = $scope;
      this.$http = $http;
      this.Notes = Notes;
      this.Site = Site;
      this.User = User;
      this.organizationDetail;
      this.host = location.host;
      this.protocol = location.protocol;
      this.Modal = Modal;
      this.totalItems = 1;
      this.currentPage = 1;
      this.pageSize = 10;
      this.toastr = toastr;
      this.$uibModal = $uibModal;
      this.$uibModal = $uibModal;
      this.contacts = [];
      this.notes = [];

      this.associatedWith = {
        'renewable': 'Renewable',
        'safer_at_work': 'Safer at Work'
      };

      let siteUrl = window.location.href;
      this.exportFeatureEnable = siteUrl.includes('devwindmanager') || siteUrl.includes('localhost') ? true : false;

      Auth.isLoggedInAsync((loggedIn) => {
        if (loggedIn) {
          this.init();
          this.currentUser = Auth.getCurrentUser();
        } else {
          $state.go('login');
        }
      });
    }

    init() {
      this.getOrganizationDetails();
      this.getContactsOfOrganization();
      this.getNotesOfOrganization();
      this.getImportJobsOfOrganization();
      // this.filterNotesData(this.currentPage);
    }

    getOrganizationDetails() {
      this.Organization.get({ id: this.$stateParams.id })
        .$promise.then((response) => {
          if (response.data) {
            let organizationDetail;
            organizationDetail = response.data;
            organizationDetail.sites = [];
            this.Site.get({
              id: "for-org",
              controller: this.$stateParams.id,
            }).$promise.then((response) => {
              if (response.data && response.data.length) {
                organizationDetail.sites = response.data;
              }
              organizationDetail.admins = [];
              this.User.get({
                id: "org-admins",
                controller: this.$stateParams.id,
              }).$promise.then((response) => {
                if (response.data && response.data.length) {
                  organizationDetail.admins = response.data;
                }
              });
            });
            this.organizationDetail = organizationDetail;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    setPage(pageNo) {
      this.currentPage = pageNo;
    }

    pageChanged() {
      console.log('pageChanged', this.currentPage);
      this.filterNotesData(this.currentPage);
    }

    openAddSiteModal(data = {}, update = false) {
      var self = this;
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/site/addEditSiteModal/addEditSiteModal.html',
        controller: 'AddEditSiteModalCtrl',
        controllerAs: 'aESite',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Add New Site',
            organization: {
              _id: this.organizationDetail._id,
              name: this.organizationDetail.name,
            },
          }
        }
      });
      // modalInstance.result.then(function(getOrganization) {
      //   self.getOrganizationDetails();
      // });
      modalInstance.result.then(function (site) {
        self.organizationDetail.sites.unshift(site.data);
      });
    }

    filterNotesData(pageNo) {
      let filterQueryData = {
        organization: this.$stateParams.id,
        currentPage: pageNo,
        pageSize: this.pageSize
      }
      if (this.searchText) {
        filterQueryData.title = this.searchText;
      }
      if (this.filterContact) {
        filterQueryData.contact = this.filterContact;
      }
      console.log('filterNotesData:', filterQueryData);
      this.Notes.filterNotes(filterQueryData).$promise
        .then(response => {
          this.notes = response.data;
          this.totalNotes = response.count;
          this.currentPage = pageNo;
          console.log('data', this.notes);
        })
        .catch(err => {
          console.log('Error filtering Notes', err);
        });
    }


    getNotesOfOrganization() {
      this.Notes.get({ organization: this.$stateParams.id, currentPage: this.currentPage, pageSize: this.pageSize })
        .$promise.then((response) => {
          if (response.success && response.data && response.data.length) {
            this.notes = response.data;
            this.totalNotes = response.count;
            console.log(this.notes);
          }
        })
        .catch((err) => {
          console.log(err);
        })
      // this.Notes.get({ currentPage: this.currentPage, pageSize: this.pageSize }).$promise
      //         .then(response => {
      //             if (response.success && response.data && response.data.length) {
      //                 this.logs = response.data;
      //                 this.totalLogs = response.count;
      //                 // this.loading = false;
      //                 this.stopSpin('spinner-2');
      //                 console.log('Activity Log:', response);
      //             }
      //         })
      //         .catch(err => {
      //             console.log(err);
      //             this.loading = false;
      //             this.stopSpin('spinner-2');
      //         })
    }

    getContactsOfOrganization() {
      this.Contact.get({ organization: this.$stateParams.id })
        .$promise.then((response) => {
          if (response.data) {
            this.contacts = response.data;
            this.contacts = _.sortBy(this.contacts, (contact) => {
              return [contact.firstName.toLowerCase()];
            });
            console.log(this.contacts);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    getImportJobsOfOrganization() {
      let queryData = {
        startDate: moment().startOf('day'),
        endDate: moment().endOf('day'),
        organization: this.$stateParams.id
      }
      this.Contact.checkImportStatus(queryData)
        .$promise.then((response) => {
          if (response.jobData) {
            this.jobsData = response.jobData;
            this.jobsData = _.sortBy(this.jobsData, job => {
              return -(new Date(job.lastRunAt)).getTime();
            });
            console.log(this.jobsData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    viewJobStatus(job) {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/view-job-status/viewJobStatus.html',
        controller: 'ViewJobStatusCtrl',
        controllerAs: 'vjs',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'View Job Status',
            job: job,
          }
        }
      });
      modalInstance.result.then(() => {
      });
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }

    openAddEditContactModal() {
      var self = this;
      var modalInstance = this.$uibModal.open({
        size: 'md',
        templateUrl: 'components/modal/contact/addEditContactModal/addEditContactModal.html',
        controller: 'AddEditContactModalCtrl',
        controllerAs: 'aEContact',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Add New Contact',
            organization: {
              id: this.organizationDetail._id,
              name: this.organizationDetail.name,
            },
          }
        }
      });
      modalInstance.result.then(function (contact) {
        self.contacts.unshift(new self.Contact(contact.data));
        self.contacts = _.sortBy(self.contacts, (contact) => {
          return [contact.firstName.toLowerCase()];
        });
      });
    }

    openAddEditNoteModal() {
      var self = this;
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/notes/addEditNoteModal/addEditNoteModal.html',
        controller: 'AddEditNoteModalCtrl',
        controllerAs: 'aENote',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Add New Note',
            organization: {
              id: this.organizationDetail._id,
              name: this.organizationDetail.name,
              // isArchive: false
            },
            contacts: this.contacts
          }
        }
      });
      modalInstance.result.then(function (note) {
        // self.getNotesOfOrganization();
        self.filterNotesData(1);
        // self.notes.unshift(new self.Notes(note.data));
      });
    }

    removeNote(noteId, title) {
      var self = this;
      var remove = { _id: noteId, isArchive: true };
      this.Modal.confirm.delete(function (noteId) {
        self.Notes.update(remove).$promise
          .then(note => {
            self.filterNotesData(1);
          })
          .catch(err => {
            self.isDisabled = false;
            self.errors = {};
            self.handleError(err, form);
          });
      })(title);
      // var remove = {_id: noteId, isArchive: true}
      //           this.Notes.update(remove).$promise
      //           .then(note => {
      //               console.log(note);
      //           })
      //           .catch(err => {
      //               this.isDisabled = false;
      //               this.errors = {};
      //               this.handleError(err, form);
      //           });
    }

    editContact(contact) {
      var self = this;
      var modalInstance = this.$uibModal.open({
        size: 'md',
        templateUrl: 'components/modal/contact/addEditContactModal/addEditContactModal.html',
        controller: 'AddEditContactModalCtrl',
        controllerAs: 'aEContact',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Edit Contact',
            organization: {
              id: this.organizationDetail._id,
              name: this.organizationDetail.name,
            },
            contact: contact
          }
        }
      });
      modalInstance.result.then(function (organization) {
        console.log('contact edit success');
        self.getContactsOfOrganization();
      });
    }

    archiveContact(contact) {
      var self = this;
      var remove = { _id: contact, isArchive: true };
      this.Modal.confirm.show(success => {
        this.Contact.update(remove).$promise
          .then(contact => {
            this.toastr.success('Archive contact successfully.');
            self.getContactsOfOrganization();
          })
          .catch(err => {
            console.log(err);
            this.error = err;
            this.toastr.error('Something went wrong. please try later or contact administrator.');
          });
      }, 'Archive Contact', `<p>Are your sure you want to Archive contact.</p>`);
      // var remove = {_id: contact,isArchive: true};
      // this.Contact.update(remove);
      // console.log(this.contact);
    }

    assignPrimaryContact(site) {
      var self = this;
      var modalInstance = this.$uibModal.open({
        size: 'md',
        templateUrl: 'components/modal/assign-contact-site/assignContactSite.html',
        controller: 'assignContactSiteCtrl',
        controllerAs: 'aCSite',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Assign Primary Contact To Site',
            site: site,
            organization: {
              id: this.organizationDetail._id,
              name: this.organizationDetail.name,
            },
          }
        }
      });
      modalInstance.result.then(function (organization) {
        console.log('contact assign to site');
        self.getOrganizationDetails();
      });
    }

    viewNote(note) {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/view-note-modal/ViewNoteModal.html',
        controller: 'ViewNoteModalCtrl',
        controllerAs: 'vnm',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'View Note',
            noteId: note._id,
          }
        }
      });
      modalInstance.result.then(() => {
      });
    }

    exportContacts() {
      let orgIdWithParam = this.$stateParams.id;
      if(this.exportAssociatedWith) {
        orgIdWithParam += `?associatedWith=${this.exportAssociatedWith}`;
      }
      this.$http({
        method: 'GET',
        url: "/api/v1/contacts/export/organization/" + orgIdWithParam,
        // This is important! Tell it to expect an arraybuffer
        responseType: "arraybuffer"
      })
        .success((data, status, headers, config) => {
          console.log('data', data);
          // const formatYmd = date => date.toISOString().slice(0, 10);
          const d = moment().format('YYYYMMD/hh:mm/')
          // use the saveAs library to save the buffer as a blob on the user's machine. Use the correct MIME type!
          saveAs(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), d + this.organizationDetail.name + ".xlsx");
        })
        .error((data, status, headers, config) => {
          console.log(data);
        });
    }

    importContactsModal() {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/contact-import/contact-import-modal.html',
        controller: 'ContactImportModalCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Contact Import Files',
            organization: this.$stateParams.id
          }
        }
      });
      modalInstance.result.then(() => {
        // this.init();
      });
    }
  }

  angular.module('windmanagerApp')
    .component('clientDetail', {
      templateUrl: 'app/client-detail/client-detail.html',
      controller: ClientDetailComponent,
      controllerAs: 'cdc',
    });
})();